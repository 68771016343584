import { apiConfig } from "./apiConfig";
import { handleResponse, handleError } from "./apiHelper";

export const menuApi = {
  get,
};

function get(outletId) {
  const url = `${apiConfig.url}${apiConfig.path}/menus/outlet/${outletId}?ignoreNotFound=true`;
  // const url = `http://localhost:5007/api/menus/outlet/${outletId}?ignoreNotFound=true`;
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${apiConfig.getToken()}`,
      "Content-Type": "application/json-patch+json",
    },
  };

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((menu) => {
      return menu;
    });
}
