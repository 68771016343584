import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import GridList from '@material-ui/core/GridList';
import Card from '@material-ui/core/Card';
import Chip from '@material-ui/core/Chip';
import ListItemText from '@material-ui/core/ListItemText';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import Snackbar from '@material-ui/core/Snackbar';
import Avatar from '@material-ui/core/Avatar';
import GridListTile from '@material-ui/core/GridListTile';
import { menuApi } from 'api/menuApi';
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { formatter } from "utils/formatMoney";
const Order = React.lazy(() => import('../container/Order'))

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  inline: {
    display: 'inline',
  },
}));

export default function Menu(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [errorRaised, setErrorRaised] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [outletId, setOutletId] = React.useState(props.outletId || '');
  const [tableNo, setTableNo] = React.useState(props.tableNo || '');
  const [menuList, setMenuList] = React.useState([]);
  const [category, setCategory] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState('All');
  const [locale, setLocale] = React.useState({
    currencyCode: "IDR",
    precision: 0
  });
  const history = useHistory();

  React.useEffect(() => {
    // if(isNextPageLoading)
      search()
  },[]);
  
  const search = () => {
    menuApi.get(outletId).then(
      (x) => {
        // console.log('x',x)
        let list = []
        x.items.map(x => {
          list.push(x.product.productCategory.value)
        })
        let list2 = []
        list2.push("All")
        list2.push("Recommendation")
        list2.push(...new Set(list))

        setMenuList(x.items)
        setCategory(list2)
        setLocale(x.merchant.locale)
      },
      (err) => {
        setErrorRaised(true)
        setErrorMessage(err)
      }
    )
  }

  const handleDetail = (event, id, obj) => {
    event.preventDefault();
    
    let _location = history.location
    let _route = `/menuCustomize`
    if (_location.state && _location.state.from) {
      _route = _location.state.from.pathname
      history.push({
        pathname: _route,
        state: {
          locale: locale,
          detail: obj,
          outletId: outletId,
          tableNo: tableNo,
          selectedAddon: [],
          extraNotes: '',
          quantity: 1
        } 
     })
    } else {
      history.push({
        pathname: _route,
        state: {
          locale: locale,
          detail: obj ,
          outletId: outletId,
          tableNo: tableNo,
          selectedAddon: [],
          extraNotes: '',
          quantity: 1
        } 
     })
    }
  }

  const handleChangeCategory = (category) => {
    if(selectedCategory === category)
      setSelectedCategory('All')
    else
      setSelectedCategory(category)
  }

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={errorRaised}
        onClose={() => setErrorRaised(false)}
        autoHideDuration={6000}
        message={errorMessage}
      />
      <List style={{display: 'flex', flexDirection: 'row', padding: 5,  overflow: 'auto',}}>
        {
          category.map((x) =>(
              <ListItem key={x}>
                <Chip size="small" clickable label={x} color="primary" variant="outlined" 
                  onClick={() => handleChangeCategory(x)}
                  icon={selectedCategory === x ? <DoneRoundedIcon /> : null} />
              </ListItem>
          )
        )}
      </List>
      <List className={classes.root}>
        {menuList.filter(v => selectedCategory !== 'All' ? v.product.productCategory.value === selectedCategory : true).map((x) => (
          <div key={x.id}>
            <ListItem alignItems="flex-start" onClick={(event) => handleDetail(event, x.id, x.product)}>
                <img width="80" style={{padding: 5}} src=
                    {x.product.imageGroup && x.product.imageGroup.images.length > 0
                      ? "data:image/png;base64," + x.product.imageGroup.images[0].contentInBase64 : ""} />
              <ListItemText
                primary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.inline}
                      style={{fontWeight: 600}}
                    >
                      {x.product.name}
                    </Typography>
                  </React.Fragment>}
                secondary={
                  <React.Fragment>
                    {x.product.description.substring(0, 50)}<br />
                    {
                    x.product.isDiscountedItem ?
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.inline}
                          style={{color: 'red', fontWeight: 600}}
                        >
                          {formatter.formatMoney(x.product.discountedPrice, locale.currencyCode, locale.precision)+ " "}
                        </Typography>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.inline}
                          style={{textDecorationLine: 'line-through', textDecorationStyle: 'solid'}}
                          color="textPrimary"
                        >
                          {formatter.formatMoney(x.product.price, locale.currencyCode, locale.precision)}
                        </Typography>
                      </React.Fragment>
                      : 
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {formatter.formatMoney(x.product.price, locale.currencyCode, locale.precision)}
                      </Typography>
                    }
                  </React.Fragment>
                }
              />
            </ListItem>
            <Divider />
          </div>
        ))}
    </List>
  </div>
  );
}