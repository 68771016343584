export const formatter = {
  formatMoney,
  formatMoneyNumber,
};

function formatMoney(input, locale, precision = 0) {
  if (!input || typeof input !== "number") {
    return input;
  }
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: locale,
    currencyDisplay: "symbol",
    minimumFractionDigits: precision,
  });
  return formatter.format(input);
}

function formatMoneyNumber(input, precision = 0) {
  if (!input || typeof input !== "number") {
    return input;
  }
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: precision,
  });
  return formatter.format(input);
}
